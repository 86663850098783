html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 14px;
}

#root {
  height: 100%;
}

.container {
  max-width: 100%;
}

.centered-div {
  align-items: center;
  justify-content: center;
}

.login-box {
  width: 750px;
}

.placeholder {
  vertical-align: baseline;
}

.placeholder-lg {
  min-height: 4em;
}

.page-item {
  text-align: center;
}

.user-menu .dropdown-toggle::after {
  display: none !important;
}

.client-menu .dropdown-menu {
  width: 600px;
}

.vi__container {
  margin: auto;
}

.vi__character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;
}

.react-js-cron .ant-select-selector {
  height: 36px !important;
}

.react-js-cron .ant-select-arrow {
  margin-top: -3px !important;
}

.password-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.password-input-container .password-input {
  width: 100%;
  padding-right: 40px; /* Adjust padding to make space for the button */
  box-sizing: border-box;
}

.password-input-container .toggle-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

input::-ms-reveal {
  display: none;
}

/* TODO: Remove below classes and their usages and replace them with bootstrap */

.profile-content {
  margin-left: 35%;
  margin-right: 35%;
}

.profile-detail {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  font-size: 16px;
}
