// Bootstrap overrides
$primary: #cf7f00;
$min-contrast-ratio: 3;

// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// Custom classes
.bg-grey-300 {
  background-color: $gray-300;
}

.ant-btn {
  background-color: $secondary !important;
}
